<template>
  <button :class="configButton.class" v-on:click="funcionBtn()">
    {{ configButton.text }}
    <div class="point"></div>
  </button>
</template>

<script>
export default {
  name: "ButtonBasic",
  props: {
    configButton: Object
  },
  methods: {
    funcionBtn: function () {
      this.$emit('funcionBtn')
    }
  }
}
</script>

<style scoped>

</style>
