<template>
  <VuePhoneNumberInput v-model="value" @update="updateValue"/>
</template>

<script>
import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

//Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VuePhoneNumberInput);
export default {
  name: "PhoneInput",
  components: {
    VuePhoneNumberInput
  },
  data(){
    return {
      value: ''
    }
  },
  methods: {
    updateValue(payload) {
      let result = payload
      console.log(result)
      if(result.formattedNumber){
        this.$emit('input', result.formattedNumber);
      }

    }
  }
}
</script>

<style scoped>

</style>
