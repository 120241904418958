<template>
  <section>
    <h1 class="pt-3 mt-3 text-center display-3" style="border-bottom: 2px solid #dddddd;" v-html="$props.title"></h1>
    <div v-for="characteristic in $props.characteristics" :key="characteristic.id">
      <div class="bg-white p-5 text-right" style="border-bottom: 2px solid #dddddd;" v-if="characteristic.id % 2 == 0">
        <b-row>
          <b-col>
            <h2 class="title" v-html="characteristic.title"></h2>
            <p class="description" v-html="characteristic.description"></p>
          </b-col>
          <b-col>
            <b-img :src="characteristic.image"></b-img>
          </b-col>
        </b-row>

      </div>
      <div class="bg-light p-5 text-left" style="border-bottom: 2px solid #dddddd;" v-else>
        <b-row>
          <b-col>
            <b-img :src="characteristic.image"></b-img>
          </b-col>
          <b-col>
            <h2 class="title" v-html="characteristic.title"></h2>
            <p class="description"  v-html="characteristic.description"></p>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ProductDescriptionComponent',
  props: {
    characteristics: Array,
    title: String
  }
}
</script>
<style lang="scss" scoped>
  @import "../../variables.scss";
  .title{
    color:$color-blue;
    font-size:4em;
    text-align: center;
    margin-top:2em;
  }
  .description{
    color:$color-black--light;
    font-size:3em;
    text-align: center;
    margin-top:2em;
  }
</style>
