<template>
  <ButtonComponent :variant="$props.variant" :text="$props.text" icon="calendar-check" @click="createMeeting()" style="display: inline;"></ButtonComponent>
</template>
<script>
import ButtonComponent from './ButtonComponent.vue';
export default {
  name: 'ButtonMeetingComponent',
  components:{
    ButtonComponent
  },
  props: {
    text: String,
    variant: String
  },
  methods: {
    createMeeting: function () {
      Calendly.initPopupWidget({ url: 'https://calendly.com/systemico/30min' }); return false
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "../../variables.scss";
  /** GENERAL STYLES **/
  .button-component{
    margin: 0;
    width: auto;
    border-radius: 3.2rem;
    position:relative;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    line-height: 0.5rem !important;
    .text{
      font-size: 1rem;
      min-height: 2rem;
      font-weight: normal;
      text-align: center;
      /**border:1px solid #aaaaaa;**/
    }
    .icon{
      height: 1.3rem;
      max-height: 1.3rem;
      /**border:1px solid #aaaaaa;**/
      padding: 0.1rem;
    }
    &:hover {
      box-shadow: 0px 4px 10px #006DF04D;
      .point {
          top: -0.6rem;
          right: -0.6rem;
      }
    }
    .point {
      width: 0.7rem;
      min-width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      position: absolute;
      top: 0.7rem;
      right: 0.7rem;
      transition: all 0.3s;
    }

  }
  .button-component:focus{
    background-color: $color-light-gray;
    color: $color-black;
    .point{
        background-color: $color-light-gray;
    }
  }
  /** PRIMARY VARIANT **/
  .button--primary  {
    border: 0.2rem solid $color-celeste !important;
    background-color: $color-blue !important;
    color: $color-white !important;
    .point{
        background-color: $color-blue;
    }
    &:hover {
        color:$color-white !important;
        border: 0.2rem solid $color-celeste !important;
        .point{
          background-color: $color-blue--dark;
        }
    }
  }
  /** PRIMARY VARIANT - SMALL SCREEN **/
  @media (max-width: 375px) {
    .button--primary {
      font-size: 1rem;
      min-width: auto;
      padding: 1rem 2rem 1rem 2rem;
    }
  }
  /** SECONDARY VARIANT **/
  .button--secondary {
      border: 0.2rem solid $color-blue;
      background-color: $color-white;
      color: $color-blue;
      .point {
        background-color: $color-white;
      }
      &:hover {
        background-color: $color-blue--dark;
        color: $color-white;
        border: 0.2rem solid $color-blue;
        .point {
          background-color: $color-blue;
        }
      }
    }
    @media (max-width: 375px) {
      .button--secondary {
        font-size: 1rem;
        min-width: auto;
        padding: 1rem 2rem 1rem 2rem;
      }
    }
  /** INFO VARIANT **/
  .button--info {
      border: 0.2rem solid $color-blue;
      background-color: $color-celeste;
      color: #006DF0;
      .point {
        background-color: #006DF0;
      }
      &:hover {
        background-color: $color-blue;
        color:$color-white;
        border: 0.2rem solid $color-blue;
        .point {
          opacity: 1;
        }
      }
    }
    @media (max-width: 375px) {
      .button--info {
        font-size: 1rem;
        min-width: auto;
        padding: 1rem 2rem 1rem 2rem;
      }
    }
  /** GRAY VARIANT **/
  .button--gray {
      background-color: #DBDEE6;
      color: $color-white;
      .point {
          background-color: #DBDEE6;
      }

      &:hover {
          .point {
              background-color: #006DF0;
          }
      }
    }
    @media (max-width: 375px) {
      .button--gray {
        font-size: 1rem;
        min-width: auto;
        padding: 1rem 2rem 1rem 2rem;
      }
    }
    /** WARNING VARIANT **/
    .button--warning {
      border: 0.2rem solid $color-yellow--dark !important;
      background-color: $color-yellow !important;
      color: $color-black !important;
      .point{
          background-color: $color-yellow;
      }
      &:hover {
          color:$color-black !important;
          border: 0.2rem solid $color-yellow !important;
          background: $color-yellow--dark !important;
          .point{
            background-color: $color-yellow;
          }
      }
    }
    /** WARNING VARIANT - SMALL SCREEN **/
    @media (max-width: 375px) {
      .button--primary {
        font-size: 1rem;
        min-width: auto;
        padding: 1rem 2rem 1rem 2rem;
      }
    }
    /** DANGER VARIANT **/
    .button--danger {
      border: 0.2rem solid $color-red--dark !important;
      background-color: $color-red !important;
      color: $color-white !important;
      .point{
          background-color: $color-red;
      }
      &:hover {
          color:$color-white !important;
          border: 0.2rem solid $color-red !important;
          background: $color-red--dark !important;
          .point{
            background-color: $color-red;
          }
      }
    }
    /** DANGER VARIANT - SMALL SCREEN **/
    @media (max-width: 375px) {
      .button--primary {
        font-size: 1rem;
        min-width: auto;
        padding: 1rem 2rem 1rem 2rem;
      }
    }
    /** ORANGE VARIANT **/
    .button--orange {
      border: 0.2rem solid $color-orange--dark !important;
      background-color: $color-orange !important;
      color: $color-white !important;
      .point{
          background-color: $color-orange;
      }
      &:hover {
          color:$color-white !important;
          border: 0.2rem solid $color-orange !important;
          background: $color-orange--dark !important;
          .point{
            background-color: $color-orange--dark;
          }
      }
    }
    /** ORANGE VARIANT - SMALL SCREEN **/
    @media (max-width: 375px) {
      .button--orange {
        font-size: 1rem;
        min-width: auto;
        padding: 1rem 2rem 1rem 2rem;
      }
    }
  /** WARNING VARIANT **/
  .button--warning-light {
    border: 0.2rem solid $color-orange !important;
    background-color: $color-yellow--light !important;
    color: $color-black--light !important;
    .point{
        background-color: $color-yellow--light
    }
    &:hover {
        color:$color-black !important;
        border: 0.2rem solid $color-orange !important;
        .point{
          background-color: $color-orange;
        }
    }
  }
  /** PRIMARY VARIANT - SMALL SCREEN **/
  @media (max-width: 375px) {
    .button--warning-light {
      font-size: 1rem;
      min-width: auto;
      padding: 1rem 2rem 1rem 2rem;
    }
  }
  /** SUCCESS VARIANT **/
  .button--success {
    border: 0.2rem solid $color-green--dark !important;
    background-color: $color-green-light !important;
    color: $color-green--dark !important;
    .point{
        background-color: $color-green-light;
    }
    &:hover {
        color:$color-green--dark !important;
        border: 0.2rem solid $color-green--dark !important;
        .point{
          background-color: $color-green--dark;
        }
    }
  }
  /** SUCCESS VARIANT - SMALL SCREEN **/
  @media (max-width: 375px) {
    .button--warning-light {
      font-size: 1rem;
      min-width: auto;
      padding: 1rem 2rem 1rem 2rem;
    }
  }
</style>
