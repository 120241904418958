<template>
  <section class="loadingCustom" v-if="getLoading">
    <div>
      <b-spinner type="grow" variant="dark" class="mr-2"></b-spinner>
      <b-spinner type="grow" variant="dark" class="mr-2"></b-spinner>
      <b-spinner type="grow" variant="dark"></b-spinner>
      <h1>Loading</h1>
    </div>
  </section>
</template>

<script>

export default {
  name: 'loadingCustom',

  computed: {
    getLoading: function(){
      return this.$store.getters.getLoading;
    }
  }
}
</script>
