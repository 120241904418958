<template>
  <b-container class="header_component" fluid>
    <b-navbar :type="$props.typeNav" class="fixed-top" :style="'background-color:'+$props.background+';color:'+$props.color+';'+($props.noFixed==true?'position: relative':'')" toggleable="md">
      <b-navbar-brand v-if="$props.showName && $props.showName==1" class="header_name text-white font-weight-bold m-1" href="/">
        <img :height="$props.heightLogo" :src="icon" />
      </b-navbar-brand>
      <b-navbar-brand v-else class="header_name text-white font-weight-bold m-1" href="/">
        <img :height="$props.heightLogo" :src="icon" />
      </b-navbar-brand>
      <b-navbar-brand v-if="$props.showName && $props.showName==1" class="header_name text-white font-weight-bold" href="/">
        {{$props.name}}
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <template v-for="link in $props.links">
            <b-nav-item-dropdown :type="$props.typeNav" :variant="$props.typeNav" class="ml-2" v-if="link.items && link.items.length>0" :text="link.name">
              <b-dropdown-item class="" type="dark" variant="dark" v-for="sublink in link.items" :key="sublink.id" :href="sublink.url">{{sublink.name}}</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item v-else class="ml-2" :type="$props.typeNav" :variant="$props.typeNav"  :key="link.id" :href="link.url">{{link.name}}</b-nav-item>
          </template>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right no-caret no-flip v-if="$props.showMenuProfile">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>
                <b-avatar badge badge-variant="warning" :src="$props.avatar" rounded="circle" :alt="$props.user"></b-avatar>
              </em>
            </template>
            <b-dropdown-item href="#" @click="profile()">Profile</b-dropdown-item>
            <b-dropdown-item href="#">Configuration</b-dropdown-item>
            <b-dropdown-item href="#" @click="salir()">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar style="background:transparent;">
    </b-navbar>
  </b-container>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: {
    name: String,
    icon: String,
    links: Object,
    user: String,
    avatar: String,
    background: String,
    color: String,
    showMenuProfile: Boolean,
    showButtonUpgrade: Boolean,
    showName: Number,
    noFixed: {
      type: Boolean,
      default: false
    },
    heightLogo:{
      type: String,
      default: '40px'
    },
    typeNav: {
      type: String,
      default: 'dark'
    }
  },
  methods: {
    salir: function () {
      localStorage.clear()
      window.open('/api/glogin/logout', '_self')
    },
    profile: function () {
      this.$bvModal.show("modal-profile")
    }
  },
  beforeMount() {
    if (this.showMenuProfile === undefined){
      this.showMenuProfile = false;
    }
    if (this.$props.typeNav === '' || this.$props.typeNav === undefined){
      this.$props.typeNav = 'dark';
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styles/variables.scss';
  .header_component{
    .navbar-toggler {
      background-color: transparent; // Fondo transparente para el botón
      &.collapsed .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='white'%3E%3Cpath stroke='white' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      }
      .header_name{
        font-weight: 500;
        font-size: 1.3rem;
        color:#ffffff;
      }
      .b-nav-dropdown{
        color: #ffffff;
      }
      .nav-link {
        color: #ffffff!important;
        font-size: 1rem;
      }
      .nav-element{
        font-size: 1rem;
        .nav-link {
          span {
            color: #ffffff !important;
          }
        }
      }

      .nav-element a {
        font-size: 1rem;
        font-weight: 500;
        span{
          color:#ffffff !important;
        }
      }
      .nav-element a:hover{
        color:#222222;
      }
       .navbar-light{
         .navbar-nav{
           .nav-link{

           }
         }
       }
    }
  }
</style>
