<template>
  <section :class="'deck variant-'+$props.variant">
    <h1 class="title" v-html="$props.title"></h1>
    <h4 class="description" v-html="$props.description"></h4>
    <b-row>
      <b-col class="p-5" md="3" v-for="item in $props.items" :key="item.id">
        <b-card
          :title="item.title"
          :img-src="item.image"
          img-alt="Image"
          img-top
          tag="article"
          class="item m-2"
        >
          <b-card-text v-html="item.description"></b-card-text>
          <ButtonComponent text="Learn more" variant="primary" />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import ButtonComponent from "../buttons/ButtonComponent.vue";

export default {
  name: 'DeckComponent',
  components: {ButtonComponent},
  props: {
    title: String,
    description: String,
    items: Array,
    variant: String
  }
}
</script>
<style lang="scss" scoped>
  @import "../../basic.scss";
  .variant-dark{
      background:$color-black;
  }
  .variant-blue{
    background:$color-blue--dark;
  }
  .deck{
    padding: 1em;
    font-size: 14px;
    .title{
      color:$color-white;
      font-size:2em;
      text-align: center;
      margin-top:1em;
    }
    .description{
      color:$color-white;
      font-size:1.5em;
      text-align: center;
      font-weight:lighter;
      /**margin-top:2em;**/
    }
    .item{
      border-radius: 1em;
      overflow: hidden;
      .card-title{
        font-size:1.5em;
        color:$color-blue--dark;
      }
    }
  }
</style>
