import { render, staticRenderFns } from "./CardSections.vue?vue&type=template&id=446bbed8&scoped=true"
import script from "./CardSections.vue?vue&type=script&lang=js"
export * from "./CardSections.vue?vue&type=script&lang=js"
import style0 from "./CardSections.vue?vue&type=style&index=0&id=446bbed8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446bbed8",
  null
  
)

export default component.exports