<template>
  <section class="cards_section" style="margin:auto;">
    <b-container class="pt-5 pb-5" style="max-width: 1280px;">
      <b-row>
        <b-col>
          <h2 class="title" v-html="$props.title"></h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="(section, index) in sections" :key="index" :sm="12" :md="6" :lg="4" class="mb-4">
          <b-card class="section" header-bg-variant="dark" bg-variant="white" :img-src="section.image" img-alt="Image" img-top>
            <template #header>
              <h4 class="name" v-html="section.name"></h4>
            </template>
            <b-card-text class="description">
              {{section.description}}
            </b-card-text>
            <template #footer>
              <ButtonComponent class="p-2 pr-4" :text="$props.json_config.text_go" v-if="section.is_link" :href="section.link" variant="secondary" icon="plus"></ButtonComponent>
              <ButtonComponent class="p-2 pr-4" :text="$props.json_config.text_go"  v-else :href="section.ua" variant="secondary" icon="plus"></ButtonComponent>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import ButtonComponent from "@/packages/jumichica.ui/components/buttons/ButtonComponent.vue";

export default {
  name: "CardSections",
  components: {ButtonComponent},
  props:{
    json_config: Object,
    domain: String,
    title: String
  },
  data(){
    return{
      sections: []
    }
  },
  mounted(){
    let hostname = this.$props.domain
    let data = {
      section_id: ""+this.$props.json_config.section_id,
      type: 0,
      domain: hostname
    }
    this.$store.dispatch('fetchSections', data).then(response => {
      this.sections = response.DATA
    })
  }
}
</script>

<style lang="scss" scoped>
  .cards_section{
    background-color: #dddddd;
    padding: 1rem;
    .title {
      font-size: 2rem;
      font-weight: 600;
      text-align: center;
      margin-bottom: 2rem;
    }
    .section {
      border: #222222 3px solid !important;
      border-radius: 0.8rem;
      overflow: hidden;
      background:#222222;
      .name {
        font-size: 1.2rem;
        font-weight: 600;
        color: #fefefe;
      }
      .description {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.2rem;
      }
    }
  }
</style>
