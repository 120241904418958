<template>
  <section class="plans container-fluid" id="plans">
    <h2 class="display-3 text-center pb-4">Planes y Precios</h2>
    <b-row class="justify-content-md-center p-2">
      <b-col md="4" v-for="plan in $props.plans" :key="plan.id" style="max-width: 30rem;" >
        <b-card bg-variant="light"
                text-variant="dark"
                :title="plan.title"
                class="text-center mb-2 pb-3"
                tag="article"
        >
          <b-card-text v-html="plan.description"></b-card-text>
          <b-list-group class="mb-5">
            <b-list-group-item v-for="feature in plan.features" :key="feature.id" class="bg-transparent">
              {{feature.value}}
            </b-list-group-item>
          </b-list-group>
          <div class="pricing">
            <span class="pricing_value">{{plan.pricing}}</span>
            <span class="pricing_currency">{{plan.currency}}</span> /
            <span class="pricing_frecuency">{{plan.frecuency}}</span>
            &nbsp;
          </div>
          <ButtonComponent class="mr-2 mb-2" :text="plan.labelButton" variant="secondary" :href="plan.link"></ButtonComponent>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import ButtonComponent from '../buttons/ButtonComponent.vue'
export default {
  name: 'PlansComponent',
  components: { ButtonComponent },
  props: {
    plans: Array
  }
}
</script>
<style lang="scss" scoped>
@import "../../../jumichica.ui/basic.scss";
  .plans{
    background:#fefefe;
    font-size:1.5em;
    padding:5em;
    h2{

    }
    .card{
      border-radius:0.8em;
      border:2px solid $color-blue;
      h4{
        font-size:2em;
      }
      .pricing{
        padding-bottom: 1em;
      }
      .pricing_value{
        font-size:1.8em;
        color:$color-celeste;
        font-weight:bold;
      }
      .pricing_frecuency{
        font-sie: 0.8em;
      }
      .pricing_currency{
        border-radius:0.3em;
        background-color: $color-orange;
        color:#fefefe;
        padding:0.2em;
        font-size:0.6em;
      }
    }
  }
</style>
