<template>
  <b-container fluid class="content_html">
    <b-container  style="max-width: 1280px">
      <h1 class="title" v-html="$props.title"></h1>
      <p class="content_html" v-html="$props.html"></p>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "ContentHTML",
  props: {
    html: String,
    title: String
  }
}
</script>

<style>
.content_html{
  background: #dddddd;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border:5px solid #222222;
  .title {
    font-weight: bold;
    font-weight: 2rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
  .content_html{
    font-size: 1.2rem;
    line-height: 1.5rem;
    background-color: #fefefe;
    padding:2rem;
  }
}
</style>
