<template>
  <section>
    <b-nav align="center">
      <b-nav-item href="#what">Descripción</b-nav-item>
      <b-nav-item href="#benefits">Beneficios</b-nav-item>
      <b-nav-item href="#features">Características</b-nav-item>
      <b-nav-item href="#plans">Precios</b-nav-item>
    </b-nav>
  </section>
</template>
<script>
export default {
  name: 'ProductNavComponent',
  components: {
  }
}
</script>
<style lang="scss" scoped>
  .nav{
    background-color: #124727;
    position: sticky;
    top: 0;
    margin-top:-10px;
  }
  .nav-link{
    font-size: 1.2em;
    color:#FFFFFF;
    font-weight:400;
    margin-left:1em;
    border-right: 1px dotted #7abaff;
  }
  .nav-link:hover{
    font-size: 1.2em;
    color:#fefefe;
  }
</style>
