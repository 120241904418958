<template>
    <section class="pricing_plans_component">
        <h2 class="title">Pricing and plans</h2>
          <div class="pricing_plans_component_content">
            <b-card-group deck class="align-items-center align-content-center">
            <b-card v-for="plan in plans"
                  :key="plan.id"
                  header-bg-variant="light"
                  header-class="text-center"
                  text-variant="dark"
                  class="m-0 p-0 mr-5"
                  style="display:inline;"
            >
            <template #header>
              <h5>{{plan.name}}</h5>
            </template>
            <b-card-title>
              <div>
                <span class="pricing">
                  {{plan.value | formatNumberMoney($props.currency).slice(0, -3)}}
                </span>
                <span class="currency">
                  {{plan.currency}}
                </span>
                <br>
                <spans class="frecuency">
                  {{plan.frecuency.toLowerCase().trim()}}
                </spans>
              </div>
            </b-card-title>
            <b-card-text>
              <p class="description">{{plan.description}}</p>
              <ul>
                <li class="" flush v-for="(feature, index) in plan.features" :key="index">
                    {{feature}}
                </li>
            </ul>
            </b-card-text>
            <template #footer>
              <ButtonComponent class="mt-1" style="width: 100%" size="sm"
                               text="Adquirir"
                               variant="success"
                               @click="suscribe(plan.id, plan.value, $props.currency)"  />
            </template>
          </b-card>
          </b-card-group>
          </div>
      </section>
</template>

<script>
import axios from "axios";
import ButtonComponent from "../buttons/ButtonComponent.vue";

var self
export default {
  name: 'PricingPlansComponent',
  components: {ButtonComponent},
  emits: ['updateBalance'],
  data () {
    return {
      valueToRecharge:0,
      plans:{}
    }
  },
  props: {
    id: String,
    productId: Number,
    planId: Number,
    value: Number,
    variant: String,
    text: String,
    icon: String,
    type: String,
    paymentsHost: String,
    publicKey: String,
    apiClient: String,
    currency: String,
    plansList: Object
  },
  beforeMount () {
    if (this.plansList !== undefined) {
      this.plans = this.plansList
    }
    else {
      if (this.modalSize === undefined) {
        this.modalSize = 'md'
      }
      if (this.type !== 'CASH') {
        this.getPlans()
      }
    }
  },
  methods: {
    startPayment:function(){
      this.$bvModal.show('payments-modal-'+this.id)
      this.valueToRecharge = this.value
      self = this
    },
    async getPlans(){
      const data = { currency: this.currency, product_id: parseInt(this.productId), lang: "ES" }
      const result = await axios.post(this.paymentsHost + 'products/get_plans', data).then(response => {
        return response.data
      });
      this.plans = result.DATA.plans
      console.log("Planes")
      console.log(this.plans)

    },
    async recharge(){
      console.log("LOAD: PAYMENT MODAL.");
      // GET THE REFERENCE FROM API
      const datos =  {
        api_client: this.apiClient,
        currency: "COP",
        value: Number(this.valueToRecharge),
        product_id: this.productId,
        platform: "WOMPI"
      }

      const result = await axios.post(this.paymentsHost+'transactions/create', datos).then(response => {
        this.$bvModal.hide('payments-modal-'+this.id)
        //CARGAMOS LA MODAL DE WOMPI
        var checkout = new WidgetCheckout({
          currency: this.currency,
          amountInCents: this.valueToRecharge+'00',
          reference:  response.data['DATA']['reference'],
          publicKey: this.publicKey
        });
        var transaction;
        checkout.open(function (result) {
        transaction = result.transaction
        if (transaction.status === 'APPROVED') {
          // TODO: UPDATE CODE.
          self.$emit('approval', self.valueToRecharge)
          console.log('APPROVED - ADD PAYMENT'+  self.valueToRecharge)
        }
        else{
          console.log('REJECTED')
        }
      });

      });
    },
    async suscribe(plan_id, value, currency){
      this.$bvModal.hide('payments-modal-'+this.id)
      console.log("LOAD: PAYMENT MODAL - PLANS - "+plan_id+".");
      // GET THE REFERENCE FROM API
      const datos =  {
        api_client: this.apiClient,
        currency: currency,
        plan_id: parseInt(plan_id),
        product_id: parseInt(this.productId),
        value: value,
        platform: "WOMPI"
      }

      const result = await axios.post(this.paymentsHost+'transactions/create', datos).then(response => {
        this.$bvModal.hide('payments-modal')
        //CARGAMOS LA MODAL DE WOMPI
        var checkout = new WidgetCheckout({
          currency: currency,
          amountInCents: value+'00',
          reference:  response.data['DATA']['reference'],
          publicKey: this.publicKey
        });
        var transaction;
        checkout.open(function (result) {
        transaction = result.transaction
        if (transaction.status === 'APPROVED') {
          // TODO: UPDATE CODE.
          self.$emit('approval', self.valueToRecharge)
          console.log('APPROVED - ADD PAYMENT'+  self.valueToRecharge)
        }
        else{
          console.log('REJECTED')
        }
      });

      });
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../variables.scss";
  /** MODAL STYLES **/
  .pricing_plans_component{
    font-family:$font-quicksand;
    padding:2rem;
    background:#6610f2;
    .title{
      font-size: 2.5rem;
      height: 2.7rem;
      text-align: center;
      font-weight: bolder;
      margin-bottom: 2rem;
      color:#fefefe;
    }
    .pricing_plans_component_content{
      width: auto;
      padding:0;
      margin:auto;
      .card-deck{
        /** CENTER CARDS **/
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .card {
          align-self: center;
          margin-right: 0.1rem;
          min-width:18rem;
          width:18rem;
          max-width:18rem;
          .card-header{
            padding:0.1rem;
            h5{
              margin-top:0.5rem;
              font-size:1.2rem !important;
              padding:0;
              text-transform: capitalize;
            }
          }
          .card-body{
            padding:0.5rem;
            margin:0;
          }
          .card-title{
            padding:0;
            margin-top:1rem;
            text-align: center;
            .pricing{
              font-size: 2rem;
              font-weight:bold;
            }
            .currency{
              font-size: 0.5em;
              font-weight: bolder;
              text-align: center;
              background-color: #42b983;
              color:#fefefe;
              width: 60px;
              border-radius: 5px;
              margin:auto;
              padding-left: 0.1rem;
              padding-right: 0.1rem;
            }
            .frecuency{
              font-size: 1.3rem;
              color:#cccccc;
            }
          }
          .card-text{
            padding:3px;
            .description{
              font-size: 1rem;
              border-bottom: 1px dotted #dddddd;
              padding-bottom:0.5rem;
            }
            ul{
              padding: 0;
              margin: 0;
              margin-left:1.5rem;
              li{
                font-size:0.9rem;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
</style>