<template>
  <section id="faqs" class="faqs" role="tablist" :style="'background:'+$props.background+';color:'+$props.textColor">
    <h2 class="display-3 text-center font-weight-bold" :style="'color:'+$props.textColor" v-html="$props.title"></h2>
    <p class="display-4 text-center pl-5 pr-5" v-html="$props.subtitle"></p>
    <b-card no-body class="mb-1" v-for="faq in faqs" :key="faq.id">
      <b-card-header header-tag="header" class="p-0" role="tab">
        <a v-b-toggle="'accordion-'+faq.id" :style="'color:'+($props.textFaqColor?$props.textFaqColor:$props.textColor)">{{faq.title}}</a>
      </b-card-header>
      <b-collapse :id="'accordion-'+faq.id" accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <b-card-text :style="'color:'+($props.textAnsFaqColor?$props.textAnsFaqColor:$props.textColor)">{{ faq.content }}</b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </section>
</template>

<script>
export default {
  name: 'FaqProductComponent',
  props: {
    title: String,
    subtitle: String,
    background: String,
    textColor: String,
    textFaqColor: String,
    textAnsFaqColor: String,
    faqs: Array
  },
}
</script>
<style lang="scss" scoped>
  .faqs {
    font-size:1.2em;
    padding-left:3em;
    padding-right:3em;
    background:#f8f8f8;
    padding-top:2em;
    padding-bottom:2em;
    border-top: 2px solid #aaaaaa;
    @media (max-width: 768px) {
      padding-left:0;
      padding-right:0;
    }
    h2{
      font-size: 2em;
      font-weight: normal;
      color: #222222;
      margin-bottom:3rem;
    }
    p{
      font-size: 1em;
      color:#aaaaaa;
    }
  }
  .card{
    background: transparent;
    padding:0.5rem;
    border: none;
    margin-left: 10%;
    margin-right: 10%;
    .card-header {
      font-size: 2.2em;
      padding: 0.2rem;
      background:transparent;
      border-bottom: 3px solid #7abaff;
      a{
        font-size: 1.5rem;
        cursor: pointer;
        color:#fefefe;
        text-decoration: none;
      }
  }
  .card-body{
    background: transparent;
    color: #111111;
  }
}
</style>
