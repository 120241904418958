import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
const apiAxios = axios.create({
  headers: { 'Content-Type': 'application/json' }
})

Vue.use(Vuex, apiAxios)

export default new Vuex.Store({
  state: {
    cargando: false
  },
  mutations: {
    setLoading (state, payload) {
      state.cargando = payload
    }
  },
  actions: {
    fetchComponents ({ commit }, payload) {
      return axios.post('sections/get_components', payload).then((response) => {
        try {
          return response.data
        } catch (error) {
          console.error(error)
          console.log('SYSTEMICO - DUCKSITES - COMPONENTS - API ERROR.')
        }
      })
    },
    fetchSections ({ commit }, payload) {
      return axios.post('sections/get_list_sections', payload).then((response) => {
        try {
          return response.data
        } catch (error) {
          console.error(error)
          console.log('SYSTEMICO - DUCKSITES - SECTIONS - API ERROR.')
        }
      })
    },
    fetchCompany ({ commit }, payload) {
      return axios.post('company/get', payload).then((response) => {
        try {
          return response.data
        } catch (error) {
          console.error(error)
          console.log('SYSTEMICO - DUCKSITES - COMPANY - API ERROR.')
        }
      })
    }
  },
  modules: {
  },
  getters: {
    getLoading: state => {
      return state.cargando
    }
  }
})
