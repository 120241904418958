<template>
  <section class="banner_stats" :style="'background:'+$props.background+';color:'+$props.textColor+';margin:auto;'">
    <b-container style="max-width: 1280px;">
      <h2 v-html="$props.title" class="display-3 text-center"></h2>
      <b-row>
        <b-col md="3" v-for="item in $props.items" :key="item.id" class="p-3">
          <b-card
            style="max-width: 30rem;border-radius:0;border:2px solid #222222;min-height:auto;background-color: transparent;"
            class="m-2 bg-white"
          >
            <b-card-text class="text-center" >
              <div>
                <b-img :src="item.icon" height="70rem" alt="" class="mb-3" ></b-img>
              </div>
              <div class="title" v-html="item.title"></div>
              <div class="value" v-html="item.value"></div>
              <div class="subtitle" v-html="item.subtitle"></div>
            </b-card-text>
        </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'BannerStatsComponent',
  props: {
    title: String,
    items: Array,
    background: String,
    textColor: String,
    titleColor: String,
    valueColor: String,
  }
}
</script>
<style lang="scss" scoped>
.banner_stats{
  padding-top: 1rem;
  border-top: 2px solid v-bind(textColor);
  h2 {
    font-size:2rem;
    margin-top:2rem;
    font-weight:bold;
  }
  .title{
    font-size: 1.8vm;
    text-align: center;
    color: v-bind(titleColor);
    font-weight: bold;
  }
  .subtitle{
    font-size: 1.1vm;
    text-align: center;
    color:v-bind(textColor);
    font-weight: light;
  }
  .value{
    font-size: 3.5vw;
    color: v-bind(valueColor);
    font-weight: bolder;
  }
}
</style>
