import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// VUE META
import VueMeta from 'vue-meta'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueMeta)

Vue.config.productionTip = false
Vue.filter('formatNumberMoney', function (value, currency) {
  var result = ''
  if (currency == 'COP') {
    result = parseInt(value).toLocaleString('es-CO', { currency: 'COP', style: 'currency' })
  } else if (currency == 'USD') {
    result = parseInt(value).toLocaleString('en-US', { currency: 'USD', style: 'currency' })
  } else if (currency == 'CAD') {
    result = parseInt(value).toLocaleString('en-CA', { currency: 'CAD', style: 'currency' })
  }
  return result
})
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/api/'
  Vue.prototype.$wsPayments = 'https://systemico.net/payments/'
} else {
  axios.defaults.baseURL = 'https://67e8-181-55-21-184.ngrok-free.app/api/'
  //axios.defaults.baseURL = 'https://cdalaisla.com/api/'
  Vue.prototype.$wsPayments = 'http://localhost:5000/payments/'
}
Vue.prototype.$cdn = 'https://cdn.systemico.net/systemico/'

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
