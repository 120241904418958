<template>
<section v-html="$props.html">
</section>
</template>

<script>
export default {
  name: "SimpleHTML",
  props: {
    html: String
  }
}
</script>

<style>
.title-laisla{
  font-size: 2rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #1e934c;
}
.text-laisla{
  font-size: 1rem;
  font-family: inherit;
  color: #343a40 ;
}
</style>
