<template>
  <section class="entry">
    <b-row>
      <b-col cols="12"><h2 v-html="$props.title"></h2></b-col>
      <b-col cols="12"><h4 >Autor: <span class="entry-author" v-html="$props.author"></span></h4></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" v-html="$props.html"></b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: "entry",
  props:{
    title: String,
    author: String,
    html: String
  }
}
</script>

<style scoped>
.entry{
  padding: 1%;
  margin-top: 90px;
  text-align: left;
  h2{
    font-weight: bold;
  }
  h4{
    font-size: 1.2rem;
    border-bottom: 10px dashed #949494;
  }
}
.entry-author{
  font-size: 1.2rem;
  font-weight: bold;
}
</style>
