<template>
  <section class="testimonials_component">
    <h2>{{$props.title}}</h2>
    <b-container v-for="item in items" :key="item.id" fluid>
      <b-row class="p-0 m-0">
        <b-col md="5" sm="12" class="d-none d-md-block text-right">
          <b-img-lazy height="300px" :src="item.image" alt="Lazy image" class="rounded" />
        </b-col>
        <b-col md="7" sm="12">
          <b-card no-body class="mt-4 mb-2 rounded testimonial">
            <b-card-body>
              <b-card-text class="text-center p-2">
                <div class="quote">”</div>
                <div class="message" v-html="item.message"></div>
                <div class="author mt-3" v-html="item.author"></div>
                <div class="role" v-html="item.role"></div>
                <div class="company" v-html="item.company"></div>
                <div class="place" v-html="item.place"></div>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>
<script>
export default {
  name: 'TestimonialsComponent',
  props: {
    items: Array,
    lang: {
      default: 'EN',
      type: String
    },
    background: String,
    textColor: String,
    title: String
  },
  data ( ) {
    return {
      translation: {
        title: {
          ES: 'Testimonios.',
          EN: 'Testimonials',
          FR: 'Témoignages'
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .testimonials_component {
    background: v-bind('background');
    color: v-bind('textColor');
    padding: 20px;
    h2 {
      color: white;
      text-align: center;
    }
    @media screen and (min-width: 768px) {
      .testimonial {
        width: 80%;
        margin-left: -50px;
        border: 3px solid #dddddd;
      }

      .quote {
        font-size: 7rem;
        font-weight: bold;
        color: #dddddd;
        font-family: "Roboto", sans-serif;
        margin-top: -3rem;
      }
      .message {
        font-size: 1.1rem;
        margin-top: -5rem;

      }
    }
    @media screen and (max-width: 768px) {
      .testimonial{
        border:3px solid #dddddd;
      }
      .quote{
        font-size: 2rem;
      }
      .message {
        font-size:1rem;
      }
    }
    .author{
      font-weight: bold;
      font-size:1rem;
    }
    .role{
      font-size:0.9rem;
    }
    .company{
      font-weight: 700;
      font-size:0.9rem;
    }
    .place{
      font-size:0.9rem;
    }
  }
</style>
