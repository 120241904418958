<template>
<b-container fluid class="team_component" style="max-width:1280px;">
    <h2 class="title">{{ title }}</h2>
    <b-card-group deck style="margin:auto;">
      <b-card v-for="(person, index) in team"
              class="mt-2 border"
              :key="index"
              style="min-width:24rem;width:24rem;max-width:24rem;"
      >
        <template #header>
          <b-avatar :src="person.image" size="3rem" class="mr-3"></b-avatar>
        </template>
        <b-card-body>
          <h4 class="card-title" v-html="person.name"></h4>
          <p v-html="person.description"></p>
        </b-card-body>
        <template #footer>
          <a :href="person.linkedin" target="_blank">
            <b-icon icon="linkedin" variant="info" style="width: 20px; height: 20px;"></b-icon></a>
        </template>
      </b-card>
    </b-card-group>
</b-container>
</template>

<script>
export default {
  name: "CardsPeople",
  props: {
    team: Array,
    background: String,
    textColor: String,
    title: String
  }
}
</script>

<style scoped>
  .team_component{
    padding-bottom:5rem;
    padding-top:5rem;
    background-color: #f8f9fa;
    .title{
      font-size:2rem;
      margin-bottom:3rem;
    }
    .card{
      font-size: 1.1rem;
    }
  }
</style>
